<template>
    <vs-tabs class="invoice-list">
      <vs-tab label="Doctor Details">
        <div>
          <doctor-edit
            fetchClinicsBy="superAdmin"
            :canDelete="true"
            :adminApprovalVar="true"
          />
        </div>
      </vs-tab>
      <!-- <vs-tab label="Organisation">
        <div>
          <associated-organization-table :userId="doctorId" />
        </div>
      </vs-tab> -->
      <vs-tab label="Clinics" id="clinicsTab">
        <div class="w-full">
            <associated-clinics
              :inTabEdit="true"
              :userId="doctorId"
              :isDoctor="true"
              userTypeToEdit="doctor"
            />
        </div>
      </vs-tab>
      <vs-tab label="Treatments" id="treatmentsTab">
        <vs-card>
          <!-- <template v-slot:header>
            <h4>Doctor Treatment History</h4>
          </template> -->
          <treatment-history></treatment-history>
        </vs-card>
      </vs-tab>
      <vs-tab label="Permissions" id="permissionsTab" v-if="staffIsNotOrgOwner">
          <Permissions :name="doctorData.firstName" :id="doctorData._id"/>
      </vs-tab>
    </vs-tabs>
</template>
<script>
import doctorEdit from "../../components/doctors/editDoctor.vue";
import treatmentHistory from "../../../views/doctor/history/TreatmentHistory.vue";
import AssociatedClinics from "../../components/org-owner/others/associatedClinics.vue";
import AssociatedOrganizationTable from "../../components/organization/associatedOrganizationTable.vue";
import Permissions from "../../components/org-owner/others/permissions.vue";
import { mapActions } from "vuex";

export default {
  components: {
    doctorEdit,
    treatmentHistory,
    AssociatedClinics,
    AssociatedOrganizationTable,
    Permissions,
  },
  data: () => ({
    doctorData: {
      _id: null,
      firstName: "",
      lastName: "",
    },
    doctorId: null,
  }),
  methods: {
    ...mapActions("doctor", ["fetchDoctorDetail"]),
    async getDoctorDetail(doctorId){
      await this.fetchDoctorDetail(doctorId).then((res) => {
        const doctor = res.data.data;
        this.doctorData = {
          _id: doctor._id,
          firstName: doctor.firstName,
          lastName: doctor.lastName,
        };
      });
    }
  },
  async created() {
    let doctorId = this.$route.params.doctorId;
    this.doctorId = doctorId;
    await this.getDoctorDetail(this.doctorId);
  },
  computed: {
    staffIsNotOrgOwner() {
      return (
        (this.$store.state.AppActiveUser.userRole === "doctor" ||
          this.$store.state.AppActiveUser.userRole === "nurse" ||
          this.$store.state.AppActiveUser.userRole === "basic") &&
        (localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`) !=
          "Org Owner" ||
          this.$store.state.AppActiveUser.id != this.nurseId)
      );
    },
  },
};
</script>
